import { Controller } from "@hotwired/stimulus"

// Connects to data-conroller="inline-search"
export default class extends Controller {
  static targets = [ "form", "rows"]

  connect() {
    this.formTarget.querySelector("input[type=search]").value = ""
  }


  search(event) {
    event.preventDefault();

    this.rowsTarget.querySelectorAll("[data-inline-search-value]").forEach(function(element) {
      if (element.dataset.inlineSearchValue.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) {
        element.classList.remove("visually-hidden");

      } else {
        element.classList.add("visually-hidden");
      }
    });
  }
}
