import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cart-items"
export default class extends Controller {
  static targets = [ "quantity" ]
  static values = {
    standard: { type: Number, default: 1 }
  }

  decreaseQuantity(element) {
    element.preventDefault();
    this.quantityTarget.value = Math.max(0, this.quantity - 1);
    if (this.quantityTarget.value == 0) {
      this.hideQuantity(this.quantityTarget.closest(".cart-quantity-wrapper"));
    }
  }

  increaseQuantity(element) {
    element.preventDefault();
    this.quantityTarget.value = this.quantity + 1;
  }

  showQuantity(element) {
    element.preventDefault(); // button.cart-quantity-block
    element.target.classList.add("visually-hidden");
    element.target.closest(".cart-quantity-wrapper").querySelector(".input-group").classList.remove("visually-hidden");
    element.target.closest(".cart-quantity-wrapper").querySelector('input.quantity').value = this.standardValue;
  }

  hideQuantity(element) {
    element.querySelector(".input-group").classList.add("visually-hidden"); // cart-quantity-wrapper
    element.querySelector("button.cart-quantity-block").classList.remove("visually-hidden");
    element.querySelector('input.quantity').value = 0;
  }

  get quantity() {
    let quantity = parseInt(this.quantityTarget.value);
    if (Number.isNaN(quantity)) { return 0 } else { return quantity }
  }
}
